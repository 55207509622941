//load userBNBBalance
export const loadUserBnbBalance = async (library, userAddress) => {
    const result = await library.eth.getBalance(userAddress);
    return library.utils.fromWei(result, 'ether');
};

//load userLqyBalance
export const loadUserLqyBalance = async (library, lqyContract, userAddress) => {
    const result = await lqyContract.methods.balanceOf(userAddress).call();
    return library.utils.fromWei(result, 'ether');
};

//load userLpBalance
export const loadUserLpBalance = async (library, lpContract, userAddress) => {
    const result = await lpContract.methods.balanceOf(userAddress).call();
    return library.utils.fromWei(result, 'ether');
};
///////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////

//load userStake
export const loadUserStake = async (library, stakingContract, userAddress) => {
    const result = await stakingContract.methods.getUserLPAmountStaked(userAddress).call();
    return library.utils.fromWei(result, 'ether');
};

export const loadMinDaysToUnstake = async (stakingContract) => {
    const result = await stakingContract.methods.getMinimumDaysToUnStake().call();
    return result;
};

export const loadClaimInterval = async (stakingContract) => {
    const result = await stakingContract.methods.getClaimInterval().call();
    return result;
};

//load userStakeDays
export const loadUserStakeDays = async (stakingContract, userAddress) => {
    const result = await stakingContract.methods.LP_StakeDaysCount(userAddress).call();
    return result;
};

//load user Percentage
export const loadUserPercentage = async (stakingContract, userAddress) => {
    const result = await stakingContract.methods.getUserPercentage(userAddress).call();
    return result;
};

// Get User's Last Claim Amount
export const loadUserLastClaim = async (stakingContract, userAddress) => {
    const result = await stakingContract.methods.getUserLastClaim(userAddress).call();
    return result;
};

// Get User's Last Claim Time
export const loadUserLastClaimTime = async (stakingContract, userAddress) => {
    const result = await stakingContract.methods.getUserLastClaimTime(userAddress).call();
    return result;
};

// Get General Last Claim Time
export const loadGeneralLastClaimTime = async (stakingContract) => {
    const result = await stakingContract.methods.getGeneralLastClaimTime().call();
    return result;
};

// Get Current Reward Total
export const loadCurrentRewardTotal = async (library, stakingContract) => {
    const result = await stakingContract.methods.getCurrentRewardTotal().call();
    return library.utils.fromWei(result, 'ether');
};

// Get CurrentStakeTotal
export const loadCurrentStakeTotal = async (library, stakingContract) => {
    const result = await stakingContract.methods.getCurrentStakeTotal().call();
    return library.utils.fromWei(result, 'ether');
};

// Get Current Reward Claimed
export const loadCurrentRewardClaimed = async (library, stakingContract) => {
    const result = await stakingContract.methods.getCurrentRewardClaimed().call();
    return library.utils.fromWei(result, 'ether');
};

// Get Total Reward Claimed
export const loadTotalRewardClaimed = async (library, stakingContract) => {
    const result = await stakingContract.methods.getTotalRewardClaimed().call();
    return library.utils.fromWei(result, 'ether');
};

// Get Total LP Amount Staked
export const loadTotalLPAmountStaked = async (library, stakingContract) => {
    const result = await stakingContract.methods.getTotalLPAmountStaked().call();
    return library.utils.fromWei(result, 'ether');
};

// Get Total Stakers
export const loadTotalStakers = async (stakingContract) => {
    const result = await stakingContract.methods.getTotalStakers().call();
    return result;
};

// Get CurrentClaimPeriod
export const loadCurrentClaimPeriod = async (stakingContract) => {
    const result = await stakingContract.methods.getCurrentClaimPeriod().call();
    return result;
};

// Get UserLastClaimPeriod
export const loadUserLastClaimPeriod = async (stakingContract, userAddress) => {
    const result = await stakingContract.methods.getUserLastClaimPeriod(userAddress).call();
    return result;
};


//load USD Allowance //Also used for other tokens
export const loadUsdAllowance = async (library, usdContract, userAddress, stakingContractAddr) => {
    const balance = await usdContract.methods.allowance(userAddress, stakingContractAddr).call();
    return library.utils.fromWei(balance, 'ether')
};
///////////////////////////////////////////////////////////////



//Approve USD //Also used for other tokens
export const approveUsdSpending = async (
    library, usdContract, usdContractAddress, stakingContractAddr, address, investAmount
) => {

    if (investAmount.toString().trim() === "" || investAmount <= 0) {
        return {
            status: "Please Input Stake Amount.",
        };
    }

    // Error: Please pass numbers as strings or BN objects to avoid precision errors.
    // Cause is, the toWei wants a string, so this is the solution
    // Or this String(investAmount)
    // investAmount = investAmount.toString();

    //set up transaction parameters
    const transactionParameters = {
        to: usdContractAddress, // Required except during contract publications.
        from: address, // must match user's active address.
        data: usdContract.methods.approve(stakingContractAddr, library.utils.toWei(String(investAmount), 'ether')).encodeABI(),
    };

    // sign the transaction
    try {
        const txHash = await library.eth.sendTransaction(transactionParameters);
        return {
            status: (
                "✅ Approve Successful."
            ),
            smUpdate: (`Smart Contract Updated ${txHash.transactionHash}`)
        };
    } catch (error) {
        return {
            status: error.message,
        };
    }
};

export const stakeLpToken = async (library, stakingContractAddress, stakingContract, address, stakeAmount) => {

    if (stakeAmount.toString().trim() === "" || stakeAmount <= 0) {
        return {
            status: "Please Input Stake Amount.",
        };
    }

    // Error: Please pass numbers as strings or BN objects to avoid precision errors.
    // Cause is, the toWei wants a string, so this is the solution
    // Or this String(investAmount)
    // stakeAmount = stakeAmount.toString();

    //set up transaction parameters
    const transactionParameters = {
        to: stakingContractAddress, // Required except during contract publications.
        from: address, // must match user's active address.
        // value: library.utils.toHex(library.utils.toWei(stakeAmount, 'ether')),
        data: stakingContract.methods.stakeLP(library.utils.toWei(String(stakeAmount), 'ether')).encodeABI(),
    };

    // sign the transaction
    try {
        const txHash = await library.eth.sendTransaction(transactionParameters);
        return {
            status: (
                "✅ You have successfully staked MBP tokens."
            ),
            smUpdate: (`Smart Contract Updated ${txHash.transactionHash}`)
        };
    } catch (error) {
        return {
            status: error.message,
        };
    }
};

export const unstakeLpToken = async (library, stakingContractAddress, stakingContract, address, unstakeAmount) => {

    if (unstakeAmount.toString().trim() === "" || unstakeAmount <= 0) {
        return {
            status: "Please Input Unstake Amount.",
        };
    }

    // Error: Please pass numbers as strings or BN objects to avoid precision errors.
    // Cause is, the toWei wants a string, so this is the solution
    // Or this String(investAmount)
    // unstakeAmount = unstakeAmount.toString();

    //set up transaction parameters
    const transactionParameters = {
        to: stakingContractAddress, // Required except during contract publications.
        from: address, // must match user's active address.
        // value: library.utils.toHex(library.utils.toWei(unstakeAmount, 'ether')),
        data: stakingContract.methods.unstakeLP(library.utils.toWei(String(unstakeAmount), 'ether')).encodeABI(),
    };

    // sign the transaction
    try {
        const txHash = await library.eth.sendTransaction(transactionParameters);
        return {
            status: (
                "✅ You have successfully unstaked MBP tokens."
            ),
            smUpdate: (`Smart Contract Updated ${txHash.transactionHash}`)
        };
    } catch (error) {
        return {
            status: error.message,
        };
    }
};

//Claim Reward
export const claimReward = async (library, stakingContractAddress, stakingContract, address) => {

    //set up transaction parameters
    const transactionParameters = {
        to: stakingContractAddress, // Required except during contract publications.
        from: address, // must match user's active address.
        data: stakingContract.methods.claimReward().encodeABI(),
    };

    // sign the transaction
    try {
        const txHash = await library.eth.sendTransaction(transactionParameters);
        return {
            status: (
                "✅ Claim Successful. Once the transaction is verified by the network, you will get your reward."
            ),
            smUpdate: (`Smart Contract Updated ${txHash.transactionHash}`)
        };
    } catch (error) {
        return {
            status: error.message,
        };
    }
};
