import React, { useRef } from "react";
// import React, { useRef } from "react";
import { useEffect, useState } from "react";

// import axios from 'axios'

// import { ReactComponent as Emoji } from './images/emoji.svg';

import {
  // loadUserBnbBalance,
  loadUserLqyBalance,
  loadUserLpBalance,
  loadUserStake,
  loadMinDaysToUnstake,
  loadClaimInterval,
  loadUserStakeDays,
  loadUserPercentage,
  loadUserLastClaim,
  loadUserLastClaimTime,
  loadGeneralLastClaimTime,
  loadCurrentRewardTotal,
  loadCurrentStakeTotal,
  loadCurrentRewardClaimed,
  loadTotalRewardClaimed,
  loadTotalLPAmountStaked,
  loadTotalStakers,
  loadUsdAllowance,
  approveUsdSpending,
  stakeLpToken,
  unstakeLpToken,
  claimReward,
  loadCurrentClaimPeriod,
  loadUserLastClaimPeriod
} from "./util/interact.js";


///////////////////////////////////////////////////////////////////////////////////////////////////
import {
  useWeb3React,
  UnsupportedChainIdError
} from "@web3-react/core";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected
} from "@web3-react/injected-connector";
import {
  URI_AVAILABLE,
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect
} from "@web3-react/walletconnect-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorFrame } from "@web3-react/frame-connector";

import {
  injected,
  // network,
  walletconnect,
  // walletlink,
  // ledger,
  // trezor,
  // frame,
  // fortmatic,
  // portis,
  // squarelink,
  // torus,
  // authereum
} from "./connectors";
import { useEagerConnect, useInactiveListener } from "./hooks";
///////////////////////////////////////////////////////////////////////////////////////////////////




///////////////////////////////////////////////////////////////////////////////////////////////////
const connectorsByName = {
  Injected: injected,
  // Network: network,
  WalletConnect: walletconnect,
  // WalletLink: walletlink,
  // Ledger: ledger,
  // Trezor: trezor,
  // Frame: frame,
  // Fortmatic: fortmatic,
  // Portis: portis,
  // Squarelink: squarelink,
  // Torus: torus,
  // Authereum: authereum
};


function getErrorMessage(error) {

  if (error) {
    if (error instanceof NoEthereumProviderError) {
      return {
        status: "No BSC browser extension detected, install MetaMask on desktop or visit from a DApp browser on mobile.",
      };
    } else if (error instanceof UnsupportedChainIdError) {
      return {
        status: "You're connected to an unsupported network.",
      };
    } else if (
      error instanceof UserRejectedRequestErrorInjected ||
      error instanceof UserRejectedRequestErrorWalletConnect ||
      error instanceof UserRejectedRequestErrorFrame
    ) {
      return {
        status: "Please authorize this website to access your account.",
      };
    } else {
      return {
        status: "WalletConnect connection rejected by user.",
      };
    }
  } else {
    // console.error(error);
    return {
      status: "FLP LP Token Staking",
    };
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////



const AppEngine = () => {

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  const context = useWeb3React();
  const {
    connector,
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error
  } = context;

  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = useState();
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector);

  ///////////////////////////////////////////////////////////////////////////////////////////////////



  const FLH_CONTRACT_ABI = require("./contracts/df-contract-abi.json");
  const FLH_CONTRACT_ADDRESS = "0x6a5d132eaa57C033997Ab6591BD44093628A2E6c";

  const LP_CONTRACT_ABI = require("./contracts/lp-contract-abi.json");
  const LP_CONTRACT_ADDRESS = "0x8736B0AFce8977A611f11edfAf8Eb41f483A65D2";

  const STAKING_CONTRACT_ABI = require("./contracts/staking-contract-abi.json");
  const STAKING_CONTRACT_ADDRESS = "0xd334EAEC8bDE72c3e7c570D760042aBa1F293ae8";





  //state variables
  const [status, setStatus] = useState("FLP LP Token Staking");
  //for tracking smart contract update so the whole app refresh to broadcast the update
  //since we are not using socket that can listen to smart contract events
  const [smUpdate, setSmUpdate] = useState("");


  const [spinner, setSpinner] = useState(false);

  const [stakeAmount, setStakeAmount] = useState("");
  const [unstakeAmount, setUnstakeAmount] = useState("");

  // const [userBnbBalance, setUserBnbBalance] = useState(0);
  const [userLqyBalance, setUserLqyBalance] = useState(0);
  const [userLpBalance, setUserLpBalance] = useState(0);
  const [userStake, setUserStake] = useState(0);
  const [minDaysToUnstake, setMinDaysToUnstake] = useState(0);
  const [claimInterval, setClaimInterval] = useState(0);
  const [userStakeDays, setUserStakeDays] = useState(0);
  const [userPercentage, setUserPercentage] = useState(0);
  const [userLastClaim, setUserLastClaim] = useState(0);
  const [userLastClaimTime, setUserLastClaimTime] = useState(0);
  const [generalLastClaimTime, setGeneralLastClaimTime] = useState(0);
  const [currentRewardTotal, setCurrentRewardTotal] = useState(0);
  const [currentStakeTotal, setCurrentStakeTotal] = useState(0);
  const [currentRewardClaimed, setCurrentRewardClaimed] = useState(0);
  const [totalRewardClaimed, setTotalRewardClaimed] = useState(0);
  const [totalLPAmountStaked, setTotalLPAmountStaked] = useState(0);
  const [totalStakers, setTotalStakers] = useState(0);
  const [lpAllowance, setLpAllowance] = useState(0);
  const [currentClaimPeriod, setCurrentClaimPeriod] = useState(0);
  const [userLastClaimPeriod, setUserLastClaimPeriod] = useState(0);



  // load price Data // called only once

  // useEffect(() => {

  //   axios.get('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=mobipad')
  //     .then((res) => {
  //       setMbpPrice(res.data[0].current_price);
  //     })
  //     .catch((err) => {
  //       console.log("An HTTP error occured");
  //     })

  // }, []);


  //connection dependent calls in conditional useEffect
  useEffect(() => {

    if (library) {

      /**
       * NOW library is the web3 instance
       * NOW ROUTER_CONTRACT is the contract instance
       */
      // Initialise Staking contract
      var STAKING_CONTRACT = new library.eth.Contract(
        STAKING_CONTRACT_ABI,
        STAKING_CONTRACT_ADDRESS
      );

      async function fetchMinDaysToUnstake() {
        const result = await loadMinDaysToUnstake(STAKING_CONTRACT);
        setMinDaysToUnstake(result);
      }
      async function fetchClaimInterval() {
        const result = await loadClaimInterval(STAKING_CONTRACT);
        setClaimInterval(result);
      }
      async function fetchGeneralLastClaimTime() {
        const result = await loadGeneralLastClaimTime(STAKING_CONTRACT);
        setGeneralLastClaimTime(result);
      }
      async function fetchCurrentRewardTotal() {
        const result = await loadCurrentRewardTotal(library, STAKING_CONTRACT);
        setCurrentRewardTotal(result);
      }
      async function fetchCurrentStakeTotal() {
        const result = await loadCurrentStakeTotal(library, STAKING_CONTRACT);
        setCurrentStakeTotal(result);
      }
      async function fetchCurrentRewardClaimed() {
        const result = await loadCurrentRewardClaimed(library, STAKING_CONTRACT);
        setCurrentRewardClaimed(result);
      }
      async function fetchTotalRewardClaimed() {
        const result = await loadTotalRewardClaimed(library, STAKING_CONTRACT);
        setTotalRewardClaimed(result);
      }
      async function fetchTotalLPAmountStaked() {
        const result = await loadTotalLPAmountStaked(library, STAKING_CONTRACT);
        setTotalLPAmountStaked(result);
      }
      async function fetchTotalStakers() {
        const result = await loadTotalStakers(STAKING_CONTRACT);
        setTotalStakers(result);
      }
      async function fetchCurrentClaimPeriod() {
        const result = await loadCurrentClaimPeriod(STAKING_CONTRACT);
        setCurrentClaimPeriod(result);
      }

      fetchMinDaysToUnstake();
      fetchClaimInterval();
      fetchGeneralLastClaimTime();
      fetchCurrentRewardTotal();
      fetchCurrentStakeTotal();
      fetchCurrentRewardClaimed();
      fetchTotalRewardClaimed();
      fetchTotalLPAmountStaked();
      fetchTotalStakers();
      fetchCurrentClaimPeriod();

    }

    if (error) {
      const { status } = getErrorMessage(error);
      setStatus(status);
    }

  }, [library, chainId, smUpdate]);


  // wallet dependent calls in conditional useEffect
  useEffect(() => {

    if (library && account) {

      /**
       * NOW library is the web3 instance
       * NOW ROUTER_CONTRACT is the contract instance
       */
      // Initialise LQY contract
      var FLH_CONTRACT = new library.eth.Contract(
        FLH_CONTRACT_ABI,
        FLH_CONTRACT_ADDRESS
      );
      // Initialise LP contract
      var LP_CONTRACT = new library.eth.Contract(
        LP_CONTRACT_ABI,
        LP_CONTRACT_ADDRESS
      );
      // Initialise Staking contract
      var STAKING_CONTRACT = new library.eth.Contract(
        STAKING_CONTRACT_ABI,
        STAKING_CONTRACT_ADDRESS
      );


      // async function fetchUserBnbBalance() {
      //   const result = await loadUserBnbBalance(library, account);
      //   setUserBnbBalance(result);
      // }
      async function fetchUserLqyBalance() {
        const result = await loadUserLqyBalance(library, FLH_CONTRACT, account);
        setUserLqyBalance(result);
      }
      async function fetchUserLpBalance() {
        const result = await loadUserLpBalance(library, LP_CONTRACT, account);
        setUserLpBalance(result);
      }

      async function fetchUserAmountStaked() {
        const result = await loadUserStake(library, STAKING_CONTRACT, account);
        setUserStake(parseFloat(result));
      }

      async function fetchUserStakeDays() {
        const result = await loadUserStakeDays(STAKING_CONTRACT, account);
        setUserStakeDays(parseFloat(result));
      }

      async function fetchUserPercentage() {
        const result = await loadUserPercentage(STAKING_CONTRACT, account);
        setUserPercentage(parseFloat(result));
      }
      async function fetchUserLastClaim() {
        const result = await loadUserLastClaim(STAKING_CONTRACT, account);
        setUserLastClaim(parseFloat(result));
      }
      async function fetchUserLastClaimTime() {
        const result = await loadUserLastClaimTime(STAKING_CONTRACT, account);
        setUserLastClaimTime(parseFloat(result));
      }

      async function fetchLpAllowance() {
        const result = await loadUsdAllowance(library, LP_CONTRACT, account, STAKING_CONTRACT_ADDRESS);
        setLpAllowance(parseFloat(result));
      }

      async function fetchUserLastClaimPeriod() {
        const result = await loadUserLastClaimPeriod(STAKING_CONTRACT, account);
        setUserLastClaimPeriod(result);
      }

      // fetchUserBnbBalance();
      fetchUserLqyBalance();
      fetchUserLpBalance();
      fetchUserAmountStaked();
      fetchUserStakeDays();
      fetchUserPercentage();
      fetchUserLastClaim();
      fetchUserLastClaimTime();
      fetchLpAllowance();
      fetchUserLastClaimPeriod();


      if (error) {
        const { status } = getErrorMessage(error);
        setStatus(status);
      }
    }

  }, [library, account, chainId, smUpdate])//add the dependence in array


  // error dependent calls in conditional useEffect
  useEffect(() => {

    const { status } = getErrorMessage(error);
    setStatus(status);

  }, [error]);


  ///////////////////////////////////////////////////////////////////////////////////////////////////
  // log the walletconnect URI
  React.useEffect(() => {
    const logURI = uri => {
      // console.log("WalletConnect URI", uri);
      console.log("WalletConnect URI Available");
    };
    walletconnect.on(URI_AVAILABLE, logURI);

    return () => {
      walletconnect.off(URI_AVAILABLE, logURI);
    };
  }, []);
  ///////////////////////////////////////////////////////////////////////////////////////////////////




  const modalCloseButtonRef = useRef(null);

  const metamaskConnectPressed = async () => { //TODO: implement
    setActivatingConnector(connectorsByName["Injected"]);
    activate(connectorsByName["Injected"]);
    modalCloseButtonRef.current.click();
  };

  const walletconnectConnectPressed = async () => { //TODO: implement
    setActivatingConnector(connectorsByName["WalletConnect"]);
    activate(connectorsByName["WalletConnect"]);
    modalCloseButtonRef.current.click();
  };

  const disconnectWalletPressed = async () => { //TODO: implement
    if (active || error) {
      if (connector !== walletconnect) {//Logging out Metamask
        deactivate();
      } else {//Logging out WalletConnect
        connector.close();
      }
    }
  };


  ///////////////////////////////////////////////////////////////////////////////////////




  const onApproveTokenPressed = async () => { //TODO: implement
    setSpinner(true)
    if (active && library && account) {
      // Initialise MBP contract
      var LP_CONTRACT = new library.eth.Contract(
        LP_CONTRACT_ABI,
        LP_CONTRACT_ADDRESS
      );
      const { status, smUpdate } = await approveUsdSpending(library, LP_CONTRACT, LP_CONTRACT_ADDRESS, STAKING_CONTRACT_ADDRESS, account, stakeAmount);
      setStatus(status);
      setSmUpdate(smUpdate);
      setLpAllowance(parseFloat(stakeAmount));
      setSpinner(false)
    } else {
      setStatus("You must be connected to the Blockchain.");
      setSpinner(false)
    }

  };

  const onStakeTokenPressed = async () => { //TODO: implement
    setSpinner(true)
    if (active && library && account) {
      // Initialise the contract
      var STAKING_CONTRACT = new library.eth.Contract(
        STAKING_CONTRACT_ABI,
        STAKING_CONTRACT_ADDRESS
      );
      /**
       * NOW library is the web3 instance
       * NOW ROUTER_CONTRACT is the contract instance
       */

      const { status, smUpdate } = await stakeLpToken(library, STAKING_CONTRACT_ADDRESS, STAKING_CONTRACT, account, stakeAmount);
      setStatus(status);
      setSmUpdate(smUpdate);
      setStakeAmount(0);

      setSpinner(false)
    } else {
      setStatus("You must be connected to the Blockchain.");
      setSpinner(false)
    }

  };

  const onUnstakeTokenPressed = async () => { //TODO: implement
    setSpinner(true)
    if (active && library && account) {
      // Initialise the contract
      var STAKING_CONTRACT = new library.eth.Contract(
        STAKING_CONTRACT_ABI,
        STAKING_CONTRACT_ADDRESS
      );
      /**
       * NOW library is the web3 instance
       * NOW ROUTER_CONTRACT is the contract instance
       */

      const { status, smUpdate } = await unstakeLpToken(library, STAKING_CONTRACT_ADDRESS, STAKING_CONTRACT, account, unstakeAmount);
      setStatus(status);
      setSmUpdate(smUpdate);
      setUnstakeAmount(0);

      setSpinner(false)
    } else {
      setStatus("You must be connected to the Blockchain.");
      setSpinner(false)
    }

  };

  const onClaimRewardPressed = async () => { //TODO: implement
    setSpinner(true)
    if (active && library && account) {
      // Initialise the contract
      var STAKING_CONTRACT = new library.eth.Contract(
        STAKING_CONTRACT_ABI,
        STAKING_CONTRACT_ADDRESS
      );
      /**
       * NOW library is the web3 instance
       * NOW ROUTER_CONTRACT is the contract instance
       */

      const { status, smUpdate } = await claimReward(library, STAKING_CONTRACT_ADDRESS, STAKING_CONTRACT, account);
      setStatus(status);
      setSmUpdate(smUpdate);
      setStakeAmount(0);
      setUnstakeAmount(0);

      setSpinner(false)
    } else {
      setStatus("You must be connected to the Blockchain.");
      setSpinner(false)
    }

  };



  //the UI of our component
  return (

    <>



      <main className="details">
        <div className="container">
          <div className="m-3 p-lg-1" />
          <div className="row g-4">
            <div className="col-lg-8">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="https://decentrafund.io/">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="https://decentrafund.io/launchpad">Launchpad</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    LP Staking
                  </li>
                </ol>
              </nav>
            </div>

            <div className="col-lg-4">

              {(!active) && (
                <button
                  className="btn connect"
                  data-bs-toggle="modal"
                  data-bs-target="#connectModal"
                >
                  Connect wallet
                </button>
              )}

              {(active && account && connector) &&
                <p className="btn btn-outline-warning">

                  <img src={connector !== walletconnect ? "images/wallets/metamask.png" : "images/wallets/walletconnect.png"} alt="" />
                  {account.substring(0, 5) + "..." + account.substring(38)}
                </p>
              }

              {(active) && (
                <button
                  className="btn btn-light mb-3 px-1"
                  style={{ border: 0, background: "transparent", color: "white" }}
                  id="logoutButton"
                  onClick={disconnectWalletPressed}
                >
                  Disconnect
                </button>
              )}

            </div>

          </div>
          <div className="m-4" />
          <div className="projects project__cards projects__completed">
            <div className="p__body">
              <div className="projects-list">
                <div className="project-card detail-card">
                  <h4 className="mb-5">Stake LP</h4>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-6">
                        <div className="price">
                          <span>
                            {userLpBalance} <small>LP</small>
                          </span>
                        </div>
                        <p className="detail-head">LP Balance</p>
                      </div>
                      <div className="col-6">
                        <div className="price">
                          <span>
                            {userStake} <small>LP</small>
                          </span>
                        </div>
                        <p className="detail-head">Staked LP</p>
                      </div>
                      <div className="col-6">
                        <div className="price">
                          <span>
                            {userStakeDays} <small>Days</small>
                          </span>
                        </div>
                        <p className="detail-head">Days</p>
                      </div>
                      <div className="col-6">
                        <div className="price">
                          <span>{userPercentage} %</span>
                        </div>
                        <p className="detail-head">Share in Pool</p>
                      </div>
                    </div>
                  </div>
                  <div className="m-4" />
                  <div className="input-group input-group-lg mb-3 fc-left-text">
                    <input
                      type="number"
                      className="form-control"
                      id="stake-lp"
                      placeholder="Stake Amount"
                      aria-label="Stake Amount"
                      aria-describedby="inputGroup-sizing-lg"
                      onChange={(e) => setStakeAmount(e.target.value)}
                      value={stakeAmount}
                    />
                    <span className="input-group-text" id="inputGroup-sizing-lg">
                      LP
                    </span>
                  </div>

                  {(stakeAmount > userLpBalance) &&
                    <div className="mb-3 text-center">
                      <small className="text-danger">Insufficient LP Balance !!!</small>
                    </div>
                  }

                  <div className="m-4" />

                  <div className="col-md-12 mb-3 mt-4">

                    {(!active) && (
                      <button
                        className="btn connect mb-3 w-100"
                        data-bs-toggle="modal"
                        data-bs-target="#connectModal"
                      >
                        Connect wallet
                      </button>
                    )}

                    <div className="row">

                      {(active && account && stakeAmount > 0 && lpAllowance < stakeAmount) && (
                        <div className="col">
                          <button
                            className="btn btn-light w-100"
                            id="approveLP"
                            onClick={onApproveTokenPressed}
                            disabled={spinner}
                          >
                            {spinner ? "Waiting..." : "Approve LP"}
                          </button>
                        </div>
                      )}

                      {(active && account) && (
                        <div className="col">
                          <button
                            className="btn start w-100"
                            id="stakeTokensButton"
                            onClick={onStakeTokenPressed}
                            disabled={spinner}
                          >
                            {spinner ? "Waiting..." : "Stake LP"}
                          </button>
                        </div>
                      )}

                    </div>

                    <div className="m-4" />
                  </div>
                </div>
                <div className="project-card detail-card">
                  <h4 className="mb-5">Unstake LP</h4>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-6">
                        <div className="price">
                          <span>
                            {userStake} <small>LP</small>
                          </span>
                        </div>
                        <p className="detail-head">Staked LP</p>
                      </div>
                      <div className="col-6">
                        <div className="price">
                          <span>
                            {userStakeDays} <small>Days</small>
                          </span>
                        </div>
                        <p className="detail-head">Days</p>
                      </div>
                      <div className="col-6">
                        <div className="price">
                          <span>{userPercentage} %</span>
                        </div>
                        <p className="detail-head">Share in Pool</p>
                      </div>
                    </div>
                  </div>
                  <div className="m-4" />
                  <div className="input-group input-group-lg mb-3 fc-left-text">
                    <input
                      type="text"
                      className="form-control"
                      id="unstake-lp"
                      placeholder="Unstake Amount"
                      aria-label="Unstake Amount"
                      aria-describedby="inputGroup-sizing-lg"
                      onChange={(e) => setUnstakeAmount(e.target.value)}
                      value={unstakeAmount}
                    />
                    <span className="input-group-text" id="inputGroup-sizing-lg">
                      LP
                    </span>
                  </div>

                  {(unstakeAmount > userStake) &&
                    <div className="mb-3 text-center">
                      <small className="text-danger">Your max LP unstake is {userStake}</small>
                    </div>
                  }

                  <div className="m-4" />

                  <div className="col">

                    {(!active) && (
                      <button
                        className="btn connect mb-3 w-100"
                        data-bs-toggle="modal"
                        data-bs-target="#connectModal"
                      >
                        Connect wallet
                      </button>
                    )}

                    {(active && account) && (
                      <button
                        className="btn start w-100"
                        id="unstakeTokensButton"
                        onClick={onUnstakeTokenPressed}
                        disabled={spinner}
                      >
                        {spinner ? "Waiting..." : "Unstake LP"}
                      </button>
                    )}

                  </div>

                  <div className="m-4" />

                </div>



                <div className="project-card detail-card">

                  <h4 className="mb-5">Claim Reward</h4>

                  <p style={{ fontSize: 15, color: "bisque" }}>
                    {status}
                  </p>

                  <div className="m-4" />

                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-6">
                        <div className="price">
                          <span>
                            {currentRewardTotal} <small>FLH</small>
                          </span>
                        </div>
                        <p className="detail-head">Current Reward</p>
                      </div>
                      <div className="col-6">
                        <div className="price">
                          <span>{userPercentage} %</span>
                        </div>
                        <p className="detail-head">Your % Share</p>
                      </div>
                      <div className="col-6">
                        <div className="price">
                          <span>
                            {(userPercentage * currentRewardTotal) / 100} <small>FLH</small>
                          </span>
                        </div>
                        <p className="detail-head">Your Token Share</p>
                      </div>
                      <div className="col-6">
                        <div className="price">
                          <span>
                            {userLastClaimTime} <small>Days</small>
                          </span>
                        </div>
                        <p className="detail-head">Your Last Claim</p>
                      </div>
                    </div>
                  </div>

                  <div className="m-4" />

                  {(spinner) &&
                    <>
                      <div className="col-md-12 mb-3">

                        <div>
                          <button className="btn btn-light w-100">
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            />
                            &nbsp; Pending Txs...
                          </button>
                        </div>

                      </div>

                      <div className="m-4" />
                    </>
                  }

                  {(active && account && !spinner) && (
                    <>
                      <div className="col">
                        <button
                          className="btn start w-100"
                          id="claimRewardButton"
                          onClick={onClaimRewardPressed}
                          disabled={spinner}
                        >
                          Claim Reward ({(userPercentage * currentRewardTotal) / 100} FLH)
                        </button>
                      </div>

                      <div className="m-4" />
                    </>
                  )}

                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="other-details" className="project-other-details">
          <div className="container">
            <div className="py-4">
              <h1 className="project-title">FLH LP Stake</h1>
            </div>
            <div>
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-metric-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#metrics"
                    type="button"
                    role="tab"
                    aria-controls="metrics"
                    aria-selected="true"
                  >
                    Details
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-instructions-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#instructions"
                    type="button"
                    role="tab"
                    aria-controls="instructions"
                    aria-selected="false"
                  >
                    Instructions
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="metrics"
                  role="tabpanel"
                  aria-labelledby="pills-metric-tab"
                >
                  <div className="content-block">
                    <h2>LP Staking</h2>
                  </div>
                  <table className="table">
                    <tbody>
                      <tr>
                        <th scope="row">Claim Interval: </th>
                        <td>{claimInterval} Days</td>
                      </tr>
                      <tr>
                        <th scope="row">General Last Claim Day: </th>
                        <td>{generalLastClaimTime} Days</td>
                      </tr>
                      <tr>
                        <th scope="row">Last Claim Amount: </th>
                        <td>{userLastClaim} FLH</td>
                      </tr>
                      <tr>
                        <th scope="row">Total LP Amount Staked: </th>
                        <td>{totalLPAmountStaked} LP</td>
                      </tr>
                      <tr>
                        <th scope="row">Total Stakers: </th>
                        <td>{totalStakers} Users</td>
                      </tr>
                      <tr>
                        <th scope="row">Minimum Days To Unstake: </th>
                        <td>{minDaysToUnstake} Days</td>
                      </tr>
                      <tr>
                        <th scope="row">Fairlaunch Contract: </th>
                        <td>0x377AA8597dEBBCAAFA96D075538d41B6bE761191</td>
                      </tr>
                      <tr>
                        <th scope="row">Token Contract: </th>
                        <td>0x6a5d132eaa57C033997Ab6591BD44093628A2E6c</td>
                      </tr>
                      <tr>
                        <th scope="row">Token LP Staking Contract: </th>
                        <td>0xd334EAEC8bDE72c3e7c570D760042aBa1F293ae8</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div
                  className="tab-pane fade"
                  id="instructions"
                  role="tabpanel"
                  aria-labelledby="pills-instructions-tab"
                >
                  <div className="content-block">
                    <h2>Instructions</h2>
                    <p className="detail-head">Staking LP:</p>
                    <p>
                      By staking your LP tokens, obtained after providing liquidity
                      on DEX, you can earn substantial yields generated from
                      transaction taxes.
                    </p>
                    <p className="detail-head">How to stake:</p>
                    <p>
                      Go to the Stake LP tab, input LP amount then click Approve LP
                      button if it shows up, then click Stake LP button
                    </p>
                    <p className="detail-head">How to unstake:</p>
                    <p>
                      Go to the Unstake LP tab, input LP amount then click Unstake
                      LP button
                    </p>
                    <p className="detail-head">Minimum days to Unstake:</p>
                    <p>You can only unstake after {minDaysToUnstake} days.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>







      <div className="modal" id="connectModal" tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Connect wallet</h5>
              <button
                type="button"
                className="btn-close"
                style={{ backgroundColor: "white" }}
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={modalCloseButtonRef}
              />
            </div>
            <div className="modal-body">
              <div className="wallet-inner text-center">
                <p>Choose a provider to connect wallet </p>
                <div className="p-3" />
                <div className="wallet-btns row">
                  <div className="metamask col">
                    <p
                      className="metamask-btn"
                      id="MetaMaskButton"
                      style={{ cursor: "pointer" }}
                      onClick={metamaskConnectPressed}
                    >
                      <img src="images/wallets/metamask-lg.png" alt="" />
                      MetaMask
                    </p>
                  </div>
                  <div className="metamask col">
                    <p
                      className="metamask-btn"
                      id="WalletConnect"
                      style={{ cursor: "pointer" }}
                      onClick={walletconnectConnectPressed}
                    >
                      <img src="images/wallets/walletconnect-lg.png" alt="" />
                      WalletConnect
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>





    </>


  );
};

export default AppEngine;
